import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import ProductSetProductCard from 'Component/ProductSetProductCard';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';

import ProductCard from './ProductCard.component';
import { ARRANGEMENT_PRODUCT_TYPE_ID } from './ProductCard.config';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Pwa/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardContainer extends SourceProductCardContainer {
    containerProps() {
        const {
            hiddenActions,
            hiddenDeliveryTime,
            hiddenHoverSection,
            extended,
            isWidget,
            hideVariants,
            isArrangement,
            hiddenOmnibus,
            isNotListElement,
            variantsData,
        } = this.props;

        return {
            ...super.containerProps(),
            extended,
            isWidget,
            hideVariants,
            hiddenActions,
            hiddenDeliveryTime,
            hiddenHoverSection,
            isArrangement,
            hiddenOmnibus,
            isNotListElement,
            variantsData,
        };
    }

    render() {
        const { product: { type_id = '', show_as_set = false } = {} } = this.props;
        const isProductSetCard = type_id === ARRANGEMENT_PRODUCT_TYPE_ID && show_as_set;

        if (isProductSetCard) {
            return (
                <Subscribe to={[SharedTransitionContainer]}>
                    {({ registerSharedElement }) => (
                        <ProductSetProductCard
                            {...this.containerFunctions}
                            {...this.containerProps()}
                            registerSharedElement={registerSharedElement}
                        />
                    )}
                </Subscribe>
            );
        }

        return (
            <Subscribe to={[SharedTransitionContainer]}>
                {({ registerSharedElement }) => (
                    <ProductCard
                        {...this.containerFunctions}
                        {...this.containerProps()}
                        registerSharedElement={registerSharedElement}
                    />
                )}
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
